import { Action, action } from "easy-peasy";
import { emptyProxyObject } from "utils/utils";

export interface ErrorResponseModel {
  errorResponse: any;
  clearErrorResponse: Action<ErrorResponseModel>;
  setErrorResponse: Action<ErrorResponseModel, any>;
}

const ErrorResponseStore: ErrorResponseModel = {
  errorResponse: emptyProxyObject,

  clearErrorResponse: action((state) => {
    state.errorResponse = [];
  }),
  setErrorResponse: action((state, item) => {
    state.errorResponse = item;
  }),
};

export default ErrorResponseStore;
