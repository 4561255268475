import {
  TOKEN_TTL,
  AUTH_TOKEN,
  ENV_MAPPER,
  REFRESH_TOKEN,
} from "utils/constants";
import moment from "moment";
import CHOICES from "CHOICES";
import { mutate } from "utils/relay";
import { Cookies } from "react-cookie";
import graphql from "babel-plugin-relay/macro";
import {
  emptyProxyObject,
  getEnv,
  isEmpty,
  ProxyObjectType,
} from "utils/utils";
import { Action, Thunk, Computed, thunk, action, computed } from "easy-peasy";

const mutation = graphql`
  mutation authRefreshMutation($input: RefreshInput!) {
    refreshToken(input: $input) {
      token
      payload
      refreshToken
    }
  }
`;

const cookie = new Cookies();

const cookieDomain = (env: string) =>
  env === ENV_MAPPER.production
    ? ".v3.nuflights.com"
    : `.${env}.llc.nuflights.com`;

const UK = CHOICES.UserKind;

export interface CurrentUserModel {
  username: string;
  email: string;
}

export interface AuthModel {
  currentUser: CurrentUserModel | ProxyObjectType;
  authToken: string | null;
  refreshToken: string | null;
  isLoggedIn: Computed<AuthModel, boolean>;
  isAdmin: Computed<AuthModel, boolean>;
  setAuthToken: Action<AuthModel, string | null>;
  setRefreshToken: Action<AuthModel, string | null>;
  setCurrentUser: Action<AuthModel, CurrentUserModel | ProxyObjectType>;
  updateAuthToken: Thunk<AuthModel, any>;
  updateRefreshToken: Thunk<AuthModel, any>;
  clearAuthToken: Thunk<AuthModel>;
  clearRefreshToken: Thunk<AuthModel>;
  fetchAuthToken: Thunk<AuthModel, any>;
  initRefreshToken: Thunk<AuthModel, boolean>;
  permissions: any;
  setPermissions: Action<AuthModel, any>;
  updatePermissions: Thunk<AuthModel, any>;
}

const AuthStore: AuthModel = {
  permissions: {},
  currentUser: emptyProxyObject,
  authToken: cookie.get(AUTH_TOKEN),
  refreshToken: cookie.get(REFRESH_TOKEN),

  isLoggedIn: computed((state: any) => state.currentUser !== emptyProxyObject),
  isAdmin: computed((state: any) => state.currentUser.kind === UK.ADMIN),

  setAuthToken: action((state: any, authToken) => {
    state.authToken = authToken;
  }),

  setRefreshToken: action((state: any, refreshToken) => {
    state.refreshToken = refreshToken;
  }),

  setPermissions: action((state: any, permissions) => {
    state.permissions = permissions;
  }),

  setCurrentUser: action((state: any, currentUser) => {
    if (!isEmpty(currentUser)) {
      state.currentUser = currentUser;
    } else {
      state.currentUser = {};
    }
  }),

  updatePermissions: thunk((actions, permissions) => {
    let permissionsObj: any = {};
    permissions &&
      permissions
        ?.split(" ")
        ?.map((permission: string) => (permissionsObj[permission] = true));
    actions.setPermissions(permissionsObj);
  }),

  updateAuthToken: thunk((actions, authToken) => {
    getEnv() !== ENV_MAPPER.local
      ? cookie.set(AUTH_TOKEN, authToken.self, {
          domain: cookieDomain(getEnv()),
          path: "/",
          sameSite: "strict",
          secure: true,
          maxAge: authToken?.expiry,
        })
      : cookie.set(AUTH_TOKEN, authToken.self, { path: "/" });
    actions.setAuthToken(authToken.self);
  }),

  updateRefreshToken: thunk((actions, refreshToken) => {
    getEnv() !== ENV_MAPPER.local
      ? cookie.set(REFRESH_TOKEN, refreshToken.self, {
          domain: cookieDomain(getEnv()),
          path: "/",
          sameSite: "strict",
          secure: true,
          maxAge: refreshToken?.expiry,
        })
      : cookie.set(REFRESH_TOKEN, refreshToken.self, { path: "/" });
    actions.setRefreshToken(refreshToken.self);
  }),

  clearAuthToken: thunk((actions) => {
    window.localStorage.clear();
    getEnv() !== ENV_MAPPER.local
      ? cookie.remove(AUTH_TOKEN, {
          domain: cookieDomain(getEnv()),
          path: "/",
        })
      : cookie.remove(AUTH_TOKEN, { path: "/" });
    actions.setAuthToken(null);
    actions.setCurrentUser(emptyProxyObject);
  }),

  clearRefreshToken: thunk((actions) => {
    window.localStorage.clear();
    getEnv() !== ENV_MAPPER.local
      ? cookie.remove(REFRESH_TOKEN, {
          domain: cookieDomain(getEnv()),
          path: "/",
        })
      : cookie.remove(REFRESH_TOKEN, { path: "/" });
    actions.setRefreshToken(null);
    actions.setCurrentUser(emptyProxyObject);
  }),

  fetchAuthToken: thunk(
    (actions, { mutation, input, onSuccess, onFailure }) => {
      mutate({
        mutation,
        input: input,
        onSuccess: function (data: any) {
          let token = {
            self: "",
            expiry: new Date(),
          };
          let refreshToken = {
            self: "",
            expiry: new Date(),
          };
          let unixTime = Number(moment.unix(data.login.refreshExpiresIn || 0));
          let date = new Date(unixTime);
          if (data.login) {
            token.self = data.login.token;
            token.expiry = date;
            refreshToken.self = data.login.refreshToken;
            refreshToken.expiry = date;
          } else if (data.socialAuth) {
            token = data.socialAuth.token;
          }
          actions.updateAuthToken(token);
          actions.updateRefreshToken(refreshToken);
          onSuccess(data);
        },
        onFailure: function (messages: []) {
          actions.clearAuthToken();
          actions.clearRefreshToken();
          onFailure(messages);
        },
      });
    }
  ),

  initRefreshToken: thunk((actions, initialFetch = true) => {
    if (initialFetch && cookie.get(REFRESH_TOKEN)) {
      mutate({
        mutation,
        input: { refreshToken: cookie.get(REFRESH_TOKEN) },
        onSuccess: function (data: any) {
          let unixTime = Number(
            moment.unix(data.refreshToken.payload.exp || 0)
          );
          let date = new Date(unixTime);
          actions.updateAuthToken({
            self: data.refreshToken.token,
            expiry: date,
          });
          actions.updateRefreshToken({
            self: data.refreshToken.refreshToken,
            expiry: date,
          });
        },
        onFailure: function (messages: any) {
          actions.clearAuthToken();
          actions.clearRefreshToken();
        },
      });
    }
    setInterval(() => {
      if (cookie.get(REFRESH_TOKEN)) {
        mutate({
          mutation,
          input: { refreshToken: cookie.get(REFRESH_TOKEN) },
          onSuccess: function (data: any) {
            let unixTime = Number(
              moment.unix(data.refreshToken.payload.exp || 0)
            );
            let date = new Date(unixTime);
            actions.updateAuthToken({
              self: data.refreshToken.token,
              expiry: date,
            });
            actions.updateRefreshToken({
              self: data.refreshToken.refreshToken,
              expiry: date,
            });
          },
          onFailure: function (messages: any) {
            actions.clearAuthToken();
            actions.clearRefreshToken();
          },
        });
      }
    }, TOKEN_TTL);
  }),
};

export default AuthStore;
