/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type RefreshInput = {
    refreshToken?: string | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type authRefreshMutationVariables = {
    input: RefreshInput;
};
export type authRefreshMutationResponse = {
    readonly refreshToken: {
        readonly token: string;
        readonly payload: unknown;
        readonly refreshToken: string;
    } | null;
};
export type authRefreshMutation = {
    readonly response: authRefreshMutationResponse;
    readonly variables: authRefreshMutationVariables;
};



/*
mutation authRefreshMutation(
  $input: RefreshInput!
) {
  refreshToken(input: $input) {
    token
    payload
    refreshToken
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "RefreshPayload",
            "kind": "LinkedField",
            "name": "refreshToken",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "token",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "payload",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "refreshToken",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "authRefreshMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "authRefreshMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "41f33ba8b9e4a3adaab56b0ef625e1d0",
            "id": null,
            "metadata": {},
            "name": "authRefreshMutation",
            "operationKind": "mutation",
            "text": "mutation authRefreshMutation(\n  $input: RefreshInput!\n) {\n  refreshToken(input: $input) {\n    token\n    payload\n    refreshToken\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'ef3d4c294a93529a31a942cf36af71ab';
export default node;
