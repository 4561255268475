import { persist } from "easy-peasy";
import AuthStore, { AuthModel } from "store/auth";
import ErrorResponseStore, { ErrorResponseModel } from "store/errorResponse";
import ItemStore, { ItemModel } from "store/item";

export interface StoreModel {
  auth: AuthModel;
  item: ItemModel;
  response: ErrorResponseModel;
}

const Store: StoreModel = {
  auth: AuthStore,
  item: ItemStore,
  response: persist(ErrorResponseStore),
};

export default Store;
